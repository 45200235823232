import React from "react";
import "./style.scss";
import { setAuthType, setProfileData } from "../../store/Actions/AuthActions";
import { connect } from "react-redux";
import { useAuthContext } from "../../hooks/context/auth-context";
import { useState } from "react";
import { secondsToTime } from "../../utils/utils";
import { ResendOtp, VerifyOtp } from "../../services/api/DataUpdating";
import { useEffect } from "react";
import TurnstileWidget from "../TurnstileWidget/TurnstileWidget";
import { useMediaQuery } from "react-responsive";
import OTPInput from "react-otp-input";
import { getProfileData } from "../../services/api/DataFetching"
import { store } from "../../index";
import { CustomToast } from "../CustomToast/CustomToast";
import cogoToast from "cogo-toast";

// assets
const otpIllustration =
	process.env.REACT_APP_CDN_LINK + `assets/images/opt-illustration.png`;

const OtpVerificationMobile = (props) => {
	const {
		setTypeToStore,
		mobileNumberFromStore,
		feedbackRemindersFetch,
	} = props;

	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const { setOtpValue, otpValue, setTimer, timer } = useAuthContext();

	const [otpValidation, setOtpValidation] = useState("");
	const [hideResendOtp, setHideResendOtp] = useState(true);
	const [showCaptcha, setShowCaptcha] = useState(false);
	
	const [seconds, setSeconds] = useState(300);
	
	useEffect(() => {
		if (seconds > 0) {
			const intervalId = setInterval(() => {
				setSeconds(seconds - 1);
			}, 1000);
			

			return () => clearInterval(intervalId);
		} else {
			setHideResendOtp(false);
		}
	}, [seconds]);

    useEffect(() => {
        setTimeout(() => {
            setHideResendOtp(false);
        }, 10000 * 6);

    }, [])

	const onResendOtp = (captchaToken) => {
		if (!hideResendOtp && captchaToken) {
			let variables = {
				mobileNumber: mobileNumberFromStore,
				captchaToken: captchaToken,
			};
			let count = localStorage.getItem("resendOtpState");
			ResendOtp(
				variables,
				() => {
					localStorage.setItem(
						"resendOtpState",
						count ? parseInt(count) + 1 : 1,
					);
					setHideResendOtp(true);
					setShowCaptcha(false);
					// startTimer();
					setSeconds(300);
					setTimeout(() => {
						setHideResendOtp(false);
                    }, 10000 * 6);
				},
				() => {
					setShowCaptcha(false);
				},
			);
		}
	};

	const fetchProfileDataLocal = () => {
        // fetch profile data
        let variable = {
            userId: localStorage.getItem("userID"),
            walletDetails:false
        };
        getProfileData(variable, (response) => {
            store.dispatch(setProfileData(response));
        }, (error) => {
            if(error){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={error[0]?.message || error.toString()}
                        type={"error"}
                    />,
                );
            }
        });
    };


	const verifyOtpOperation = () => {
		let variables = {
			mobileNumber: mobileNumberFromStore,
			otp: otpValue,
		};
		VerifyOtp(variables, isDesktopOrLaptop, () => {
			feedbackRemindersFetch && feedbackRemindersFetch();
			setOtpValidation("success");
			fetchProfileDataLocal()
		});
	};

	return (
		<div className={"login-bg pt-3 px-0 pb-0"}>
			<div className={"bottom-padding-section px-3 height-100vh"}>
				{/* <p
					className={"open-600w-16s-24h text-right margin-bottom-32"}
					onClick={() => setTypeToStore("m-signin")}
				>
					Skip
				</p> */}
				<div className={"text-center"}>
					<div>
						<img src={otpIllustration} className={"mx-auto"} alt={"alt"} />
						<p className={"text-center"}>Enter verification code</p>
						<div className={"d-flex justify-content-center"}>
							<div>
								<p className={"open-600w-14s-22h opacity-60"}>OTP sent to</p>
								<p className={"open-600w-14s-22h mb-2 ml-2"}>
									{mobileNumberFromStore}
								</p>

								<div
									className={"w-fit-content margin-bottom-32 otp-input-wrapper"}
								>
									<OTPInput
										inputType="number"
										value={otpValue}
										onChange={setOtpValue}
										numInputs={4}
										inputStyle={
											otpValidation === "success"
												? "otp-input-style-active"
												: otpValidation === "error"
												? "otp-input-style-error"
												: "otp-input-style"
										}
										renderSeparator={<span></span>}
										renderInput={(props) => <input {...props} />}
									/>
								</div>
							</div>
						</div>

						<button
							className={
								"teal-22BBB0-bg white-color open-600w-16s-24h p-3 w-100 submit-button mb-3"
							}
							disabled={otpValue?.length !== 4}
							onClick={verifyOtpOperation}
						>
							Submit
						</button>
						<div
							className={"d-flex align-items-center justify-content-between"}
						>
							<p className={"open-400w-14s-22h opacity-60 mb-0"}>
								Get code <span className={"open-700w-14s-22h "}>VIA CALL</span>
							</p>

							<div
								className={"d-flex justify-content-between  align-items-center"}
							>
								<div className={"d-flex align-items-center"}>
									{/*<img src={clock} alt={'alt'} className={'icon-24'}/>*/}
									<p className={"open-600w-14s-22h opacity-60 mb-0"}>
										{secondsToTime(seconds).m}:{secondsToTime(seconds).s}
									</p>
								</div>
								<p
									className={
										"open-600w-14s-22h teal-22BBB0-color cursor-pointer mb-0 ml-3 " +
										(hideResendOtp && " disable-resend-otp")
									}
									onClick={() => setShowCaptcha(true)}
								>
									RESEND OTP
								</p>
							</div>
						</div>
						{showCaptcha && (
							<TurnstileWidget
								onSuccess={(token) => onResendOtp(token)}
								forceCaptcha={localStorage.getItem("resendOtpState") > 2}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	mobileNumberFromStore: state.AuthReducer.mobileNumber,
});
const mapDispatchToStore = (dispatch) => ({
	setTypeToStore: (data) => dispatch(setAuthType(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToStore,
)(OtpVerificationMobile);
