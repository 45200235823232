import React from 'react'
import './AnyPageFAQ.scss'

function AnyPageFAQ({heading,faqArray,width}) {
    return (
        <div className='AnyPagefaq' style={{width}}>
                <h2 className='title'>{heading}</h2>
            <div className="accordion" id="accordionExample">
            {
  faqArray.map((item,index)=>{
                return<div className="accordion-item" key={index}>
                    <h3 className="accordion-header" id={`heading${index}`}>
                        <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                            {item.question}
                        </button>
                    </h3>
                    <div id={`collapse${index}`} className="accordion-collapse collapse " aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body"  dangerouslySetInnerHTML={{__html : item.answer}}>
                        </div>
                    </div>
                </div>
                  })
} 
            </div>
        </div>
    )
}

export default AnyPageFAQ