import { fetchQuery } from "relay-runtime";
import environment from "../../environment";
import { store } from "../../index";
// import { axios } from "axios";

import GetSourceListingQuery from "../queries/GetSourceListingQuery";
import {
	setActiveBusDetail,
	setAvailableBusList,
	setDestinationPointList,
	setDropPoint,
	setPickupAndDropPointListAction,
	setPickupPoint,
	setSeatArrangementAction,
	setSelectedSeatPrice,
	setSelectedSeats,
	setSourcePointList,
} from "../../store/Actions/BookingAction";
import GetDestinationListingQuery from "../queries/GetDestinationListingQuery";
import GetAvailableBusListingQuery from "../queries/GetAvailableBusListingQuery";
import GetSeatArrangementQuery from "../queries/GetSeatArrangmentQuery";

import BoardingAndDroppingMutation from "../mutation/BoardingAndDroppingMutation";
import GetPostBookingDataQuery from "../queries/GetPostBookingDataQuery";
import { postBookingDataAction, setGreenMilesStateAction } from "../../store/Actions/CommonAction";
import cogoToast from "cogo-toast";
import GetAvailableBusListingFilterQuery from "../queries/GetAvailableBusListingFilterQuery";
import {
	amenitiesFilterAction,
	boardingPointFilterAction,
	droppingPointFilterAction,
} from "../../store/Actions/FilterAction";
import { CustomToast } from "../../components/CustomToast/CustomToast";
// import GetAllRoutesQuery from "../queries/GetAllRoutesQuery";
// import getCouponQuery from "../queries/GetCouponQuery";
import getCancelDetailsQuery from "../queries/GetCancelDetailsQuery";
import getCancelPartialDetailsQuery from "../queries/GetCancelPartialDetailsQuery";
import DownloadInvoiceUrlQuery from "../queries/DownloadInvoiceUrlQuery";
import sendTicketToEmailQuery from "../queries/SendTicketToEmailQuery";
import sendTicketToSmsQuery from "../queries/SendTicketToSmsQuery";
import CancelBasedOnCompanyQuery from "../queries/CancelBasedOnCompanyQuery";
import getTicketUrlQuery from "../queries/GetTicketUrlQuery";
import MyBookingQuery from "../queries/MyBookingQuery";
import RescheduleStatusCheckQuery from "../queries/RescheduleStatusCheckQuery";
import getCouponByReferenceNumberQuery from "../queries/GetCouponByReferenceNumberQuery";
import GetMyProfileQuery from "../queries/GetMyProfileQuery";
import GetVersionQuery from "../queries/GetVersionQuery";
import { rootReducer } from "../../store/Reducer";
import GetFailureBookingByIdQuery from "../queries/GetFailureBookingByIdQuery";
import GetWalletDetailsQuery from "../queries/GetWalletDetailsQuery";
import GetTransactionDetailsQuery from "../queries/GetTransactionDetailsQuery";
import GetCoPassengerListQuery from "../queries/GetCoPassengerListQuery";
import GetChecklistQuery from "../queries/GetChecklistQuery";
// import GetBookingByIdQuery from "../queries/ GetBookingByIdQuery";
import PendingReviewQuery from "../queries/PendingReviewQuery";
import GetBookingByGuestQuery from "../queries/GetBookingByGuestQuery";
import GetMonthlyPassDescriptionQuery from "../queries/GetMonthlyPassDescriptionQuery";

export const getSourceList = (variable) => {
	fetchQuery(environment, GetSourceListingQuery, variable)
		.toPromise()
		.then(
			(response) => {
				// props.setSourceListToStore(response.source);
				// store.dispatch((args)=> {
				// 	let data = response.source;
				// 	setSourcePointList(args, data);
				// })
				store.dispatch(setSourcePointList(response.source));
			},
			(err) => {
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
			},
		);
};

export const getDestinationList = (variable) => {
	fetchQuery(environment, GetDestinationListingQuery, variable)
		.toPromise()
		.then(
			(response) => {
				store.dispatch(
					setDestinationPointList(response.getDestinationsBySource),
				);
			},
			(err) => {
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
			},
		);
};

export const getAvailableBusList = (variable) => {
	fetchQuery(environment, GetAvailableBusListingQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getAvailableBusListing.length > 0) {
					store.dispatch(setAvailableBusList(response.getAvailableBusListing));
				} else {
					store.dispatch(setAvailableBusList([]));
					// cogoToast.error("No bus available for this route", {position: "top-center"});
					cogoToast.error(
						<CustomToast
							type={"error"}
							message={"No bus available for this route"}
							heading={"Error"}
						/>,
						{ position: "top-center" },
					);
				}
			},
			(err) => {
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
				store.dispatch(setAvailableBusList([]));
			},
		);
};

export const getAvailableBusFilterList = (
	variable,
	successCallback,
	errorCallbacj,
) => {
	fetchQuery(environment, GetAvailableBusListingFilterQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getAvailableBusListingFilter.busListing.length > 0) {
					store.dispatch(
						setAvailableBusList(
							response.getAvailableBusListingFilter.busListing,
						),
					);
					store.dispatch(setGreenMilesStateAction(false))
					store.dispatch(
						boardingPointFilterAction(
							response.getAvailableBusListingFilter.boardingPoints,
						),
					);
					store.dispatch(
						droppingPointFilterAction(
							response.getAvailableBusListingFilter.droppingPoints,
						),
					);
					store.dispatch(
						amenitiesFilterAction(
							response.getAvailableBusListingFilter.amenities,
						),
					);

					successCallback(response.getAvailableBusListingFilter.busListing);
				} else {
					store.dispatch(setAvailableBusList([]));
					store.dispatch(boardingPointFilterAction([]));
					store.dispatch(droppingPointFilterAction([]));
					store.dispatch(amenitiesFilterAction([]));
					if (errorCallbacj) {
						errorCallbacj();
					}
					// cogoToast.error("No bus available for this route", {position: "top-center"});
					// cogoToast.error(<CustomToast type={"error"} message={"No bus available for this route"} heading={"Error"}/> ,{position:"top-center"})
				}
			},
			(err) => {
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
				store.dispatch(boardingPointFilterAction([]));
				store.dispatch(droppingPointFilterAction([]));
				store.dispatch(setAvailableBusList([]));
				store.dispatch(amenitiesFilterAction([]));
			},
		);
};

export const getSeatArrangement = (variable,callback) => {
	fetchQuery(environment, GetSeatArrangementQuery, variable)
		.toPromise()
		.then(
			(response) => {
				store.dispatch(
					setSeatArrangementAction(response.seatArrangementDetails),
				);
				callback()
			},
			(err) => {
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
				callback()
			},
		);
};

export const setBoardingAndDroppingPoint = (
	variable,
	successCallback,
	errorCallback,
) => {
	BoardingAndDroppingMutation(
		variable,
		(response) => {
			if (response.getPickupDropPoints !== null) {
				if (successCallback) {
					successCallback(response.getPickupDropPoints);
				}

				store.dispatch(
					setPickupAndDropPointListAction(response.getPickupDropPoints),
				);
			}
		},
		(err) => {
			if (errorCallback) {
				errorCallback(err);
			}
			// cogoToast.error(err, {position: 'top-center'})
		},
	);
};

export const clearCurrentBusDetails = () => {
	store.dispatch(setSeatArrangementAction([]));
	store.dispatch(setSelectedSeatPrice([]));
	store.dispatch(setSelectedSeats([]));
	store.dispatch(setSeatArrangementAction([]));
	store.dispatch(setActiveBusDetail({}));
	store.dispatch(setPickupPoint([]));
	store.dispatch(setDropPoint([]));
	store.dispatch(setPickupAndDropPointListAction([]));
};

export const postBookingData = (variable, setData) => {
	fetchQuery(environment, GetPostBookingDataQuery, variable)
		.toPromise()
		.then((response, errors) => {
			if (response.getBookingById !== null) {
				if (setData) {
					setData(response.getBookingById);
				}
				store.dispatch(postBookingDataAction(response.getBookingById));
			} else {
				if (setData) {
					setData({});
				}
				store.dispatch(postBookingDataAction({}));
				// cogoToast.error(
				// 	<CustomToast
				// 		type={"error"}
				// 		message={"Something went wrong"}
				// 		heading={"Error"}
				// 	/>,
				// 	{ position: "top-center" },
				// );
			}
		});
};

export const failureBookingData = (variable, setData) => {
	fetchQuery(environment, GetFailureBookingByIdQuery, variable)
		.toPromise()
		.then((response, errors) => {
			if (response.getFailureBookingById !== null) {
				if (setData) {
					setData(response.getFailureBookingById);
				}
				store.dispatch(postBookingDataAction(response.getFailureBookingById));
			} else {
				if (setData) {
					setData({});
				}
				store.dispatch(postBookingDataAction({}));
				cogoToast.error(
					<CustomToast
						type={"error"}
						message={"Something went wrong"}
						heading={"Error"}
					/>,
					{ position: "top-center" },
				);
			}
		});
};

// unused fetching function

// export const getAllRoutesData = (variable) => {
// 	let data = [];
// 	return fetchQuery(environment, GetAllRoutesQuery, variable)
// 		.toPromise()
// 		.then(
// 			(response) => {
// 				data = response.getRoutes;
//
// 				return data;
// 			},
// 			(err) => {
// 				data = [];
// 				return data;
// 			},
// 		);
// };

// unused fetching function

// export const getCouponData = (successCallback, errorCallback) => {
// 	let data = [];
// 	return fetchQuery(environment, getCouponQuery)
// 		.toPromise()
// 		.then(
// 			(response) => {
// 				if (response.getDefaultCouponCodeDetails !== null) {
// 					response.getDefaultCouponCodeDetails.map((item, index) => {
// 						return data.push(item);
// 					});
// 					if (successCallback) {
// 						successCallback(data);
// 					}
// 					return data;
// 				}
// 			},
// 			(err) => {
// 				data = [];
// 				if (errorCallback) {
// 					errorCallback(err);
// 				}
// 				return data;
// 			},
// 		);
// };

export const getCouponByRefNoData = (
	variable,
	successCallback,
	errCallback,
) => {
	let data = [];
	return fetchQuery(environment, getCouponByReferenceNumberQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getCouponsByReferenceNo !== null) {
					response.getCouponsByReferenceNo.map((item, index) => {
						return data.push(item);
					});
					if (successCallback) {
						successCallback(data);
					}
					return data;
				}
			},
			(err) => {
				data = [];
				if (errCallback) {
					cogoToast.error(
						<CustomToast type={"error"} message={err} heading={"Error"} />,
						{ position: "top-center" },
					);
					errCallback(err);
				}
				return data;
			},
		);
};

export const getCancelDetailsData = (variable, setData) => {
	return fetchQuery(environment, getCancelDetailsQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.cancelDetails !== null) {
					setData(response.cancelDetails);
				}
			},
			(err) => {
				setData({});
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
			},
		);
};

export const getCancelPartialDetailsData = (variable, setData) => {
	return fetchQuery(environment, getCancelPartialDetailsQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.partialCancellationDetails !== null) {
					setData(response.partialCancellationDetails);
				}
			},
			(err) => {
				setData({});
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
			},
		);
};

export const DownloadInvoiceUrlData = (
	variable,
	successCallback,
	errorCallback,
) => {
	return fetchQuery(environment, DownloadInvoiceUrlQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getInvoiceUrlByBookingId !== null) {
					successCallback(response.getInvoiceUrlByBookingId);
					return response.getInvoiceUrlByBookingId;
				}
			},
			(err) => {
				return "";
				errorCallback(err);
			},
		);
};

export const sendTicketToMailData = (variable) => {
	return fetchQuery(environment, sendTicketToEmailQuery, variable)
		.toPromise()
		.then(
			(response) => {
				cogoToast.success(
					<CustomToast
						type={"success"}
						message={"Ticket sent to your mail"}
						heading={"Success"}
					/>,
					{ position: "top-center" },
				);
			},
			(err) => {
				cogoToast.error(
					<CustomToast
						type={"error"}
						message={"Something went wrong"}
						heading={"Error"}
					/>,
					{ position: "top-center" },
				);
			},
		);
};

export const sendTicketToSmsData = (variable) => {
	return fetchQuery(environment, sendTicketToSmsQuery, variable)
		.toPromise()
		.then(
			(response) => {
				cogoToast.success(
					<CustomToast
						type={"success"}
						message={"Ticket sent to your mobile"}
						heading={"Success"}
					/>,
					{ position: "top-center" },
				);
			},
			(err) => {
				cogoToast.error(
					<CustomToast
						type={"error"}
						message={"Something went wrong"}
						heading={"Error"}
					/>,
					{ position: "top-center" },
				);
			},
		);
};

export const cancelBasedOnCompanyData = (setData, errorCallback) => {
	return fetchQuery(environment, CancelBasedOnCompanyQuery)
		.toPromise()
		.then(
			(response) => {
				if (response.cancellationPolicyBasedOnCompany !== null) {
					setData(response.cancellationPolicyBasedOnCompany);
				}
			},
			(err) => {
				setData({});
				errorCallback();
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
			},
		);
};

export const getTicketUrlData = (variable, successCallback, errorCallback) => {
	return fetchQuery(environment, getTicketUrlQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getTicketUrl !== null) {
					successCallback(response.getTicketUrl);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const getMyBookingData = (variable, successCallback, errorCallback) => {
	fetchQuery(environment, MyBookingQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.myBooking !== null) {
					successCallback(response.myBooking);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const checkRescheduleStatusData = (
	variable,
	successCallback,
	errorCallback,
) => {
	fetchQuery(environment, RescheduleStatusCheckQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.checkRescheduleStatus !== null) {
					successCallback(response.checkRescheduleStatus);
				}
			},
			(err) => {
				cogoToast.error(
					<CustomToast
						heading={"Error"}
						message={err[0].message}
						type={"error"}
					/>,
				);
				errorCallback(err[0].message);
			},
		);
};

export const getProfileData = (variable, successCallback, errorCallback) => {
	fetchQuery(environment, GetMyProfileQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getMyProfile !== null) {
					successCallback(response.getMyProfile);
				} else {
					errorCallback(response.getMyProfile);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const getVersionData = (successCallback, errorCallback) => {
	fetchQuery(environment, GetVersionQuery)
		.toPromise()
		.then(
			(response) => {
				if (response.getVersion !== null) {
					if (
						!store.getState()?.CommonReducer?.version ||
						store.getState()?.CommonReducer?.version === ""
					) {
						localStorage.removeItem("persist:root");
						// localStorage.clear();


						// storage.removeItem('persist:otherKey')
						successCallback(response.getVersion.version);
						return rootReducer(undefined, "LOGOUT");
					} else if (
						response.getVersion.version !==
						store.getState()?.CommonReducer?.version
					) {
						localStorage.removeItem("persist:root");
						// localStorage.clear();

						successCallback(response.getVersion.version);
						return rootReducer(undefined, "LOGOUT");
					}
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const getWalletDetailsData = (successCallback, errorCallback) => {
	fetchQuery(environment, GetWalletDetailsQuery)
		.toPromise()
		.then(
			(response) => {
				if (response.getWalletDetails !== null) {
					successCallback(response.getWalletDetails);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const getTransactionListData = (
	variable,
	successCallback,
	errorCallback,
) => {
	fetchQuery(environment, GetTransactionDetailsQuery, variable)
		.toPromise()
		.then(
			(response) => {
				if (response.getTransactions !== null) {
					successCallback(response.getTransactions);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const getCoPassengerListData = (successCallback, errorCallback) => {
	fetchQuery(environment, GetCoPassengerListQuery)
		.toPromise()
		.then(
			(response) => {
				if (response.coPassengers !== null) {
					successCallback(response.coPassengers);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

export const getChecklistData = (successCallback, errorCallback) => {
	fetchQuery(environment, GetChecklistQuery)
		.toPromise()
		.then(
			(response) => {
				if (response.getChecklist !== null) {
					successCallback(response.getChecklist);
				}
			},
			(err) => {
				errorCallback(err);
			},
		);
};

// unused fetching function

// export const getBookingByIdData = (variable, setData) => {
// 	return fetchQuery(environment, GetBookingByIdQuery, variable)
// 		.toPromise()
// 		.then(
// 			(response) => {
// 				if (response !== null) {
// 					setData(response);
// 				}
// 			},
// 			(err) => {
// 				setData({});
// 				if(err){
// 					cogoToast.error(
// 						<CustomToast
// 							heading={"Error"}
// 							message={err[0]?.message || err.toString()}
// 							type={"error"}
// 						/>,
// 					);
// 				}
// 			},
// 		);
// };

export const getPendingReviewData = (successCallback, errorCallback) => {
	fetchQuery(environment, PendingReviewQuery)
		.toPromise()
		.then(
			(response) => {
				if (response.pendingReviews !== null) {
					successCallback(response.pendingReviews);
				}
			},
			(error) => {
				errorCallback(error);
			},
		);
};
export const guestBookingData = (variable, setData, errCallback) => {
	fetchQuery(environment, GetBookingByGuestQuery, variable)
		// .toPromise()
		// .then((res) => {
		// 	debugger
		// 	if (res.getBookingByGuest) {
		// 		if (setData) {
		// 		  setData(res.getBookingByGuest);
		// 		}
		// 		store.dispatch(postBookingDataAction(res.getBookingByGuest));
		// 	  } else {
		// 		const errorMessage = res.errors[0].message;
		// 		if (setData) {
		// 		  setData({});
		// 		}
		// 		store.dispatch(postBookingDataAction({}));
		// 		cogoToast.error(
		// 		  <CustomToast
		// 			type={"error"}
		// 			message={errorMessage}
		// 			heading={"Error"}
		// 		  />,
		// 		  { position: "top-center" }
		// 		);
		// 	  }
		// })
		// .catch((error) => {
		// 	errCallback(error);
		// 	if (setData) {
		// 		setData({});
		// 	}
		// 	store.dispatch(postBookingDataAction({}));
		// 	cogoToast.error(
		// 		<CustomToast
		// 			type={"error"}
		// 			message={error.message}
		// 			heading={"Error"}
		// 		/>,
		// 		{ position: "top-center" },
		// 	);
		// });
		.subscribe({
			error: (error) => {
				const errorMessage = error.errors[0].message;
				if (setData) {
					setData({});
				}
				store.dispatch(postBookingDataAction({}));
				cogoToast.error(
					<CustomToast
						type={"error"}
						message={errorMessage}
						heading={"Error"}
					/>,
					{ position: "top-center" },
				);
			},
			next: (data,err) => {
				if(err){
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={err[0]?.message || err.toString()}
							type={"error"}
						/>,
					);
				}
			
				if (setData) {
					setData(data.getBookingByGuest);
				}
				store.dispatch(postBookingDataAction(data.getBookingByGuest));
			},
		});
};


export const getMonthlyPassDescription=(variable,successCallback, errorCallback)=>{
	fetchQuery(environment, GetMonthlyPassDescriptionQuery,variable)
		.toPromise()
		.then(
			(response) => {
					successCallback(response.getMonthlyPassDescription);
			},
			(err) => {
				errorCallback(err);
			},
		)
		.catch((error) => {
			errorCallback(error);
		}
		);
}