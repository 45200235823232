import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import RecommendedBlogs from "../BlogLandingPage/Components/RecommendedBlogs/RecommendedBlogs";
import ExtraBlogPart from "../BlogLandingPage/Components/ExtraBlogPart";
import remarkGfm from "remark-gfm";

// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import "../Blog.scss"

function LongWeekends2025() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();



    return (
        <div className={"specific-blog-Page"}>
            <Helmet>
                <title>Long Weekend 2025: A Complete List of Where to Travel & What to Do</title>
                <link rel="canonical" href="https://www.nuego.in/blog/long-weekend-in-2025" hreflang="en-IN" />
                <meta name="description" content="Looking for long weekend plans in 2025? Explore amazing travel spots, fun activities, and everything you need for a refreshing holiday. Plan your Long Weekend in 2025 now!"/>
                <meta name="keywords" content="upcoming long weekend, long weekend travel,next long weekend,Long Weekends in 2025"></meta>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            {
                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);



    const dataContent=[
        {
         
        title:"Long Weekends in 2025: Turn Your Leaves into Endless  Vacations!",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/2025%20long%20weekends/hero%20image.jpg",
        imgAlt:"Long Weekends in 2025: Turn Your Leaves into Endless  Vacations",
        desc: `Are you dreaming of endless long weekends this year? Imagine turning just 8 leave days into an incredible 44 holidays—yes, it’s possible! With a little bit of planning and some strategic leave applications, you can enjoy extended weekends in 2025 to recharge, explore new destinations, or simply spend quality time with your loved ones. Let’s dive into the ultimate 2025 calendar with Indian holidays and make the most of the year ahead.

## Here is a list of upcoming holidays in India

### January Long Weekend

- **Dates**: Jan 11 (Saturday) - Jan 14 (Tuesday)
- **Plan**: Take leave on Jan 13 (Monday).
- **Occasion**: Makar Sankranti on Jan 14.
- **What to Do**: Start the year with a cozy winter getaway or celebrate the harvest festival with your family. Think snow-covered hills or warm countryside vibes.Attend the Kite Festival in the Pink City of [Jaipur](https://www.nuego.in/travel-itineraries/trip?city=Jaipur) and don’t miss a shopping expedition at Johri Bazaar. Experience the vast white desert of the Rann of Kutch during the vibrant Rann Utsav.

![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/2025%20long%20weekends/Makar%20Sankranti.jpg "Makar Sankranti")


### February Long Weekend
- **Dates**: Feb 22 (Saturday) - Feb 25 (Tuesday)
- **Plan**: Take leave on Feb 24 (Monday).
- **Occasion**: Maha Shivaratri on Feb 25.
- **What to Do**: Ideal for a quick spiritual [retreat](https://www.nuego.in/travel-itineraries/trip?city=Tirupati) or a serene nature escape before the summer rush begins.

### March Long Weekend
1. **March 14 (Friday) - March 16 (Sunday)**

![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/2025%20long%20weekends/Holi.jpg "Holi")

#### Holi Weekend
- No leave needed for this colorful festival! Enjoy vibrant celebrations with family and friends.

2. **March 29 (Saturday) - March 31 (Monday)**

#### Eid
- Take leave on March 31 (Monday) to extend the weekend for Eid.

**What to Do**: Celebrate [Holi’s](https://www.nuego.in/blog/guide-to-celebrate-holi-in-mathura-vrindavan) festivities with gusto, then end the month with a relaxing break during Eid.

### April Long Weekend
- **Dates**: April 18 (Friday) - April 20 (Sunday)
- **Plan**: No leave needed.
- **Occasion**: Good Friday on April 18.
- **What to Do**: Use this peaceful break to unwind or embark on a serene getaway.

### May Long Weekend
- **Dates**: May 1 (Thursday) - May 4 (Sunday)  
- **Plan**: Take leave on May 2 (Friday).  
- **Occasion**: Labour Day on May 1.  
- **What to Do**: Kickstart your summer with a [beach](https://www.nuego.in/travel-itineraries/trip?city=Chennai) holiday or a relaxing staycation

### August Long Weekends

![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/2025%20long%20weekends/Ganesh%20Chaturthi.jpg "Ganesh Chaturthi")

1. **August 15 (Friday) - August 17 (Sunday)**  

#### Independence Day weekend
- no leave needed.

2. **August 27 (Wednesday) - August 31 (Sunday)**  

#### Take leaves on Aug 28 (Thursday) and Aug 29 (Friday) for a 5-day stretch.

- **Occasion**: Ganesh Chaturthi.
- **What to Do**: Celebrate the festival with family, or plan a [monsoon](https://www.nuego.in/blog/best-august-travel-destinations-in-india-monsoon) adventure.

### September Long Weekend
- **Dates**: Sep 5 (Friday) - Sep 7 (Sunday)
- **Plan**: No leave needed.
- **Occasion**: Onam on Sep 5.
- **What to Do**: Head to [Kerala](https://www.nuego.in/) for an authentic Onam experience or enjoy a restful weekend closer to home.

### October Long Weekends

![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/2025%20long%20weekends/Diwali.jpg "Diwali")

1. **Oct 2 (Thursday) - Oct 5 (Sunday)**
- Take leave on Oct 3 (Friday) to extend the Gandhi Jayanti weekend.

2. **Oct 18 (Saturday) - Oct 20 (Monday)**
- No leave needed for Diwali on Oct 20.  
- **What to Do**: Diwali is perfect for family gatherings, while the Gandhi Jayanti weekend is great for a mini-vacation.

### December Long Weekend
- **Dates**: Dec 25 (Thursday) - Dec 28 (Sunday)
- **Plan**: Take leave on Dec 26 (Friday).
- **Occasion**: Christmas on Dec 25.
- **What to Do**: Wrap up the year with a cozy winter holiday or celebrate Christmas with loved ones.

![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/2025%20long%20weekends/Christmas.jpg "Christmas")

## Pro Tips for Making the Most of 2025 Holidays  

- **Plan Ahead**: Book your tickets for your upcoming long weekend and accommodations early to avoid peak prices. Long weekends tend to get busy quickly.  
- **Coordinate with Your Team**: Discuss leave plans with colleagues to ensure smooth workflow at the office.  
- **Explore Nearby Destinations**: Not every break needs to involve long journeys. Check out scenic spots closer to home for quick and hassle-free getaways.  

As you can see, 2025 is brimming with opportunities for a lot of long weekend travel and unforgettable experiences. By strategically planning your leave days, you can transform ordinary weekends into long holiday filled with adventure, relaxation, and cherished moments with loved ones. Whether you choose to explore new destinations or indulge in local festivities, the key is to make the most of each long weekend ,India. So, pack your bags for your next long weekend in 2025, set your plans in motion, and get ready to embrace the joy of travel and togetherness throughout the year. Here’s to making 2025 a memorable year of exploration and celebration!`       
        }
        ]

    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/blog"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>blog</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>long-weekend-in-2025</p>
                    </div>
                    }
                    <div className="containers d-flex flex-column">
                        <div className="main-blog-extra-blog">
                            {
                                dataContent.map((item, indx) => {
                                    return (
                                        <div key={indx} className="main-blog-card">
                                            <h1>{item.title}</h1>
                                            <img src={item.img} alt={item.imgAlt} />
                                            <div className="blog-content">
                                                <ReactMarkdown children={item.desc} remarkPlugins={[remarkGfm]} />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <ExtraBlogPart dataContentTitle={dataContent?.title} />
                        </div>
                        <RecommendedBlogs />
                    </div>
                </div>
            }
            <Footer />
        </>
    );
}

export default LongWeekends2025