import "./DistancePage.scss";
import React ,{ useState, useRef, useEffect }from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams} from "react-router";
import axios from "axios";

import {
  setDestinationPoint,
  setSourceAndDestination,
  setSourcePoint,
} from "../../store/Actions/BookingAction";

// reusable components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";

// components
import RouteDetails from './Components/RouteDetails/RouteDetails';
import CityDetails from "./Components/CityDetails/CityDetails";
import InnerSearchbar from "../Home/component/SearchBarRevamp/InnerSearchbar";
import Loader from "../../components/Loader/Loader";

const failureIllustrationIcon = process.env.REACT_APP_CDN_LINK + `assets/images/failure-illustration.png`


function DistancePage(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const navigate = useNavigate();

  const params = useParams();
  const paramss = params.id.split("-to-")
  const [routeDetails , setRouteDetails] = useState({})
  const [loading, setLoading] = useState(true);

  const onFlip = (to,from)=>{
    fetchData(to,from);
  }

  const fetchData = async (to,from) => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_CMS_PROD_API}api/distanceRoutes?populate=*&pagination[pageSize]=10&filters[sourceCity][cityName][$eqi]=${to}&filters[destinationCity][cityName][$eqi]=${from}`);
      console.log(Object.keys(response.data).length>0 , response.data)
      if(Object.keys(response.data).length > 0 ){
        setRouteDetails(response.data)
        setLoading(false)
      }else setLoading(false)  
    } catch (error) {
      setLoading(false)
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(paramss[0],paramss[1]);
  }, []);
  return (
    <div className="distance-landing-page">
      <Helmet>
        <title>{routeDetails?.metaTitle}</title>
        <link
          rel="canonical"
          href={routeDetails?.canonicalUrl}
          hreflang="en-IN"
        />
        <meta
          name="description"
          content={routeDetails?.description}
        />
        <meta name="keywords" content={routeDetails?.keywords}></meta>
        <meta name="robots" content="index,follow" />
      </Helmet>
      {isDesktopOrLaptop ? (
        <AuthModalLayout>
          <Navbar />
          <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props}  routeDetails={routeDetails}  loading={loading} onFlip={onFlip}/>
          <Footer  />
        </AuthModalLayout>
      ) : (
        <MobileSubScreenLayout
          back={() => navigate(-1)}
          title={"Distance"}
        >
          <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props}  routeDetails={routeDetails} loading={loading} onFlip={onFlip} />
          <Footer  />
        </MobileSubScreenLayout>
      )}
    </div>
  );
}

export const MainContent = ({ props , routeDetails , loading , onFlip ,isDesktopOrLaptop}) => {

    const navigate = useNavigate();

   

    const ref = useRef();
    const [selectedSearchFromRecent, setSelectedSearchFromRecent] = useState({});
    const [viewCalendar, setViewCalendar] = useState(false);
    const [
        viewCalendarActivatedBeforeScroll,
        setViewCalendarActivatedBeforeScroll,
    ] = useState(false);

    const {
        setSourceAndDestinationToStore,
        sourcePointListFromStore,
        destinationPointListFromStore,
        userDataFromStore,
        filterDataFromStore,
        filterFromStore,
    } = props;

    const openCalendarForMobile = (args) => {
        document.querySelector(".MobileTopBus").style.position = "relative";
        document.querySelector(".MobileTopBus").style.top = "0";

        setViewCalendar(args);
        setViewCalendarActivatedBeforeScroll(true);
    };

  return (
    loading? <Loader/> :

    Object.keys(routeDetails).length ?
      <div className="distance-main-content">

        <RouteDetails routeDetails={routeDetails} isDesktopOrLaptop={isDesktopOrLaptop} onFlip={onFlip}/>
        <CityDetails routeDetails={routeDetails}/>
        {/* <div className="searchbar-div container">
        <InnerSearchbar
            navigate={navigate}
            sourcePointListFromStore={sourcePointListFromStore}
            destinationPointListFromStore={destinationPointListFromStore}
            setSourceAndDestinationToStore={(args) =>
                setSourceAndDestinationToStore(args)
            }
            selectedSearchFromRecent={selectedSearchFromRecent}
            
            setSelectedSearchFromRecent={(args) =>
                setSelectedSearchFromRecent(args)
            }
            userDataFromStore={userDataFromStore}
            filterDataFromStore={filterDataFromStore}
            filterFromStore={filterFromStore}
            heading="Book Electric AC Bus Tickets Online"
        />
        </div> */}


    </div>

    :

    <div
    className={
        "d-flex flex-column align-items-center text-center pt-5 mt-5"
    }
>
    <img
        src={failureIllustrationIcon}
        alt={"alt"}
        className={"main-illustration margin-bottom-50"}
    />
    <p className={"ubuntu-700w-20s-32h mb-3"}>
        We’re Coming Soon!
    </p>
    <p className={"ubuntu-500w-18s-28h w-75"}>
        The route information you’re looking for is currently
        unavailable.
    </p>
    
</div>
  
  );
};

const mapStateToProps = (state) => ({
    sourcePointFromStore: state.BookingReducer.sourcePoint,
    destinationPointFromStore: state.BookingReducer.destinationPoint,
    sourcePointListFromStore: state.BookingReducer.sourcePointList,
    destinationPointListFromStore: state.BookingReducer.destinationPointList,
    userDataFromStore: state.AuthReducer,
    filterDataFromStore: state.FilterReducer.filter,
    filterFromStore: state.FilterReducer.filter
});

const mapDispatchToProps = (dispatch) => ({
    setSourcePointToStore: (data) => dispatch(setSourcePoint(data)),
    setDestinationPointToStore: (data) => dispatch(setDestinationPoint(data)),
    setSourceAndDestinationToStore: (data) =>
        dispatch(setSourceAndDestination(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DistancePage);
