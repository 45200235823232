import React, { useEffect, useState } from "react";
import "./style.scss";

//assets
import guestUserFailureIllustration from "../../assets/images/guest-user-failure-illustration.png";

import { connect } from "react-redux";
import {
	setAuthType,
	setLoginDataSubmission,
	setSignupDataSubmission,
} from "../../store/Actions/AuthActions";
import {
	GetBookingDetailsByPnrAndNameData,
	Login,
	Signup,
} from "../../services/api/DataUpdating";
import GoogleAuthMutation from "../../services/mutation/GoogleAuthMutation";
import { useNavigate } from "react-router";
import { store } from "../../index";
import { setTermsConditionsModalAction } from "../../store/Actions/CommonAction";
import cogoToast from "cogo-toast";
import { CustomToast } from "../CustomToast/CustomToast";
import TurnstileWidget from "../TurnstileWidget/TurnstileWidget";
import { useAuthContext } from "../../hooks/context/auth-context";
import { set } from "lodash";
import CustomButton from "../CustomButton";
// import TurnstileWidget from "../TurnstileWidget/TurnstileWidget";

const ErrorImage =
	process.env.REACT_APP_CDN_LINK + `assets/images/Bold-Outline.png`;
const SignInDesktop = (props) => {
	const {
		setTypeToStore,
		setLoginDataToStore,
		setSignUpDataToStore,
		typeFromStore,
	} = props;
	const navigate = useNavigate();
	const {
		activeTab,
		setActiveTab,
		setInputDirty,
		inputDirty,
		valid,
		setValid,
		setOtpValue,
	} = useAuthContext();

	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [showError, setShowError] = useState(false);
	const [touch, setTouch] = useState({
		name: false,
		pnr: false,
	});
	const [UserName, setUserName] = useState("");
	const [pnrNumber, setPnrNumber] = useState("");
	const [guestUserFailureScreen, setGuestUserFailureScreen] = useState("");
	const [
		guestUserManageBookingError,
		setGuestUserManageBookingError,
	] = useState({
		pnr: "",
		name: "",
	});
	const [showCaptcha, setShowCaptcha] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	

	useEffect(() => {
		if (activeTab === "signin") {
			console.log("in");
			window.Moengage.track_event("signin_page_viewed", {
				platform: "website",
				sign_in_origin: `${window.location.href}`,
			});
		} else {
			console.log("up");
			window.Moengage.track_event("signup_page_viewed", {
				platform: "website",
				sign_in_origin: `${window.location.href}`,
			});
		}
	}, [activeTab]);

	// const _turnstileCb=() =>{
	// 	console.debug('_turnstileCb called');
	
	// 	turnstile.render('#myWidget', {
	// 	  sitekey: '1x00000000000000000000AA',
	// 	  theme: 'light',
	// 	});
	// }
	let moeId = localStorage.getItem("moeID");

	const dataSubmission = (captchaToken) => {
		window.Moengage.track_event("login_attempt", {
            account_id: `${moeId === null ? "" : moeId}`,
            platform: `website`,
            page_viewed: "yes",
            mobile_number: `${number === null ? "" : number}`,
        });
		let loginData;
		localStorage.setItem("resendOtpState", 0);
		if (number.length === 10) {
			setShowError(false);
			
			if (activeTab === "signup") {
				loginData = {
					fullName: name?.trim(),
					mobileNumber: number,
					captchaToken: captchaToken,
				};
				Signup(
					loginData,
					() => {
						setOtpValue("");
						setTypeToStore("d-otp");
					},
					() => {
						setShowCaptcha(false);
						setIsLoading(false)
						setValid(true)
					},
				);
				setLoginDataToStore(loginData);
			} else {
				loginData = {
					mobileNumber: number,
					captchaToken: captchaToken,
				};

				Login(
					loginData,
					() => {
						setTypeToStore("d-otp");
						setOtpValue("");
					},
					() => {
						setShowCaptcha(false);
						setIsLoading(false)
						setValid(true)
					},
				);
				setSignUpDataToStore(loginData);
			}
		} else {
			setShowError(true);
			setShowCaptcha(false);
		}
	};



	const handleInpuChange = (e) => {
		// setTyping(true)
		if(e.target.value.length>10) return
		const pattern = /^[6-9]\d{9}$/;
		setNumber(e.target.value);
		if (pattern.test(e.target.value)) {
			setValid(true);
			setShowError(false);
			setInputDirty(inputDirty + 1);
		} else {
			setValid(false);
			setShowError(true);
		}

		// if (e.target.value.length < 11) {
		// 	setNumber(e.target.value);
		// 	setInputDirty(inputDirty + 1);
		// }
		// if (
		// 	((e.target.value.length >= 10 && !valid) ||
		// 		parseInt(e.target.value[0]) > 5) &&
		// 	inputDirty > 0
		// ) {
		// 	setValid(true);
		// 	setShowError(false);
		// } else {
		// 	setShowError(true);
		// 	setValid(false);
		// }
		// setTimeout(() => {
		// 	// setTyping(false)
		// }, 3000);
};
	

	const responseGoogle = (response) => {
		if (response.accessToken) {
			let variable = {
				accessToken: response.accessToken,
			};
			GoogleAuthMutation(
				variable,
				function (response) {
					if (
						response.googleAuth?.token !== null &&
						response.googleAuth?.token !== null
					) {
						localStorage.setItem("token", response.googleAuth.token);
						localStorage.setItem(
							"refreshToken",
							response.googleAuth.refreshToken,
						);
						let loginData = {
							fullName:
								response.googleAuth.user.fisrtName +
								" " +
								response.googleAuth.user.lastName,
							mobileNumber: response.googleAuth.user.mobileNumber,
						};

            setLoginDataToStore(loginData);
          }
        },
        function (err) {
          if(err){
            cogoToast.error(
                <CustomToast
                    heading={"Error"}
                    message={err[0]?.message || err.toString()}
                    type={"error"}
                />,
            );
          }
        }
      );
    }
  };

	// Guest user Booking

	const onChangeInputHandler = (e, type) => {
		if (type === "pnr") {
			const pattern = /^[1-9]\d{0,6}$/;
			if(e.target.value=="") setPnrNumber("");
			if(pattern.test(e.target.value)) setPnrNumber(e.target.value);
		} else if (type === "name") {
			const pattern = /^[a-zA-Z\s]*$/; 
			if(e.target.value=="") setUserName("");
    		if(pattern.test(e.target.value)) setUserName(e.target.value)		
		}
	};

	const blurFunction = ( type) => {
		if (type === "pnr" && pnrNumber === "") {
			setGuestUserManageBookingError({ pnr: "error", name: "" });
		} else if (type === "name" && UserName === "") {
			setGuestUserManageBookingError({ pnr: "", name: "error" });
		} else {
			setGuestUserManageBookingError({ pnr: "", name: "" });
		}
	};

	const guestUserSubmission = () => {
		if (pnrNumber === "") {
			setGuestUserManageBookingError({ pnr: "error", name: "" });
		} else if (UserName === "") {
			setGuestUserManageBookingError({ pnr: "", name: "error" });
		} else {
			let variable = {
				pnrNo: pnrNumber,
				name: UserName?.trim(),
			};
			GetBookingDetailsByPnrAndNameData(
				variable,
				() => {
					navigate("/confirmation", { state: {isManageBooking:true}} ,{ replace: false });
				},
				(error) => {
					if (error[0].message.toLowerCase().includes("booking not found")) {
						setGuestUserFailureScreen(error[0].message);
					} else {
						cogoToast.error(
							<CustomToast
								message={error[0].message}
								type={"error"}
								heading={"Error"}
							/>,
							{ position: "top-center" },
						);
					}
				},
			);
			setGuestUserManageBookingError({ pnr: "", name: "" });
		}
	};

	const handleTouch = (field) => {
		setTouch({
			...touch,
			[field]: true,
		});
	};

	return (
		<>
			{typeFromStore === "guest" && (
				<>
					<div className={"w-75  m-auto pb-5"}>
						<p className={"guest-booking-title "}>Manage Booking</p>
						{guestUserFailureScreen !== "" ? (
							<div className='guest-user-failure text-center'>
								<img
									src={guestUserFailureIllustration}
									alt='failure illustration'
									clasName='guest-user-failuer-image'
								/>
								<p className='ubuntu-700w-24s-32h black-1E1E26-color mb-3'>
									Sorry! You can’t manage your booking from NueGo
								</p>
							</div>
						) : (
							<div className='guest-user-form'>
								<p className={"guest-booking-subtitle"}>
									Enter the details below and help us retrieve your ticket for
									you.
								</p>
								<div className={"guest-user-input-searches"}>
									<>
										<label className={"guest-user-label"}>PNR</label>
										<br />
										<input
											autoFocus
											className={
												"guest-user-guest-booking " +
												(guestUserManageBookingError.pnr !== "" &&
													touch.pnr &&
													"error-state-border")
											}
											placeholder={"Enter your PNR Number"}
											value={pnrNumber}
											onBlur={(e) => blurFunction(e, "pnr")}
											onChange={(e) => onChangeInputHandler(e, "pnr")}
											onClick={(e) => handleTouch("pnr")}
										/>
										{guestUserManageBookingError.pnr !== "" && touch.pnr && (
											<div
												className={"d-flex  align-items-center validation-part"}
											>
												<img alt={"error-image"} src={ErrorImage} />
												<p className={"error-msg"}>Please enter a valid PNR</p>
											</div>
										)}
									</>
									<>
										<label className={"guest-user-label"}>First Name</label>
										<br />
										<input
											onChange={(e) => onChangeInputHandler(e, "name")}
											autoFocus
											className={
												"guest-user-guest-booking " +
												(guestUserManageBookingError.name !== "" &&
													touch.name &&
													"error-state-border")
											}
											placeholder={"Enter your First Name"}
											value={UserName}
											onBlur={(e) => blurFunction(e, "name")}
											onClick={() => handleTouch("name")}
										/>
										{guestUserManageBookingError.name !== "" && touch.name && (
											<div
												className={"d-flex  align-items-center validation-part"}
											>
												<img alt={"error-image"} src={ErrorImage} />
												<p className={"error-msg"}>Please enter your name</p>
											</div>
										)}
									</>
								</div>
								<button
									className={"guest-submit-button  text-center "}
									onClick={guestUserSubmission}
									disabled={UserName === "" | pnrNumber === ""}
								>
									Get Booking
								</button>
								<div>
									<p className={"sign-in"}>
										Already have an account?{" "}
										<span onClick={() => setTypeToStore("d-signin")}>
											Log In
										</span>{" "}
										to continue.
									</p>
								</div>
							</div>
						)}
					</div>
				</>
			)}

			{typeFromStore !== "guest" && (
				<div className={"w-75  m-auto pb-5"}>
					<p className={"open-700w-14s-22h opacity-60  mb-2"}>LOGIN</p>
					<p className={"open-700w-32s-48h mb-2"}>
						Welcome to Nue<span className={"teal-22BBB0-color"}>GO</span>
					</p>
					{/*tab */}
					<div className={"d-flex mb-3"}>
						<div className={"w-fit-content"}>
							<p
								className={`ubuntu-700w-18s-28h cursor-pointer mb-0   ${
									activeTab === "signin" ? "active-tab" : "in-active-tab"
								}`}
								onClick={() => setActiveTab("signin")}
							>
								Sign In
							</p>
							{/*{activeTab === 'signin' && <hr className={'tab-underline mb-4 mt-1'}/>}*/}
						</div>
						<div className={"pr-4"}></div>
						<div className={"w-fit-content"}>
							<p
								className={`ubuntu-700w-18s-28h cursor-pointer mb-0  ${
									activeTab === "signup" ? "active-tab" : "in-active-tab"
								}`}
								onClick={() => setActiveTab("signup")}
							>
								Sign Up
							</p>
						</div>
					</div>
					{/* name */}
					{activeTab === "signup" && (
						<>
							<p className={"open-600w-14s-22h grey-53565A-color mb-1"}>
								Enter Name
							</p>
							<div
								className={"d-flex common-input-wrap align-items-center mb-4"}
							>
								<input
									type={"text"}
									className={"input-box w-100"}
									placeholder={"Enter your Name"}
									value={name}
									onChange={(e) =>
										{
											const pattern = /^[a-zA-Z\s]*$/; 
											if(e.target.value=="") setName("");
    										if(pattern.test(e.target.value)) setName(e.target.value)
										}
										 }
								/>
							</div>
						</>
					)}
					{/* number */}
					<p className={"open-600w-14s-22h grey-53565A-color mb-1"}>
						Mobile Number
					</p>
					<div
						className={
							"d-flex  align-items-center " +
							(showError
								? " common-input-wrap-error p-3 mb-2 "
								: " common-input-wrap mb-4 ")
						}
					>
						<p className={"open-600w-16s-24h mb-0"}>+91</p>
						<hr className={"vertical-divider mx-3 my-0"} />

						<input
							type='number'
							pattern='[0-9]*'
							autoFocus
							min='0'
							maxLength={9}
							value={parseInt(number)}
							placeholder={"Enter your number"}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									setShowCaptcha(true)
								}
								else if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "." || e.key === "," || e.key === "e") {
									e.preventDefault();
								  }
							}}
							onChange={(e) => handleInpuChange(e)}
							onInput={(e) => handleInpuChange(e)}
							className={"input-box w-100"}
						/>
					</div>
					{showError && (
						<div>
							<p className={"open-600w-16s-24h red-F85959-color "}>
								Invalid Mobile Number
							</p>
						</div>
					)}

					<CustomButton 
					   ctaName={"Get OTP"} 
					   disabled={!valid}
					   onClick={() => {
						   setShowCaptcha(true)
						   setValid(false)
						   setIsLoading(true)
					   }} 
					   isLoading={isLoading}
                    />

					{showCaptcha && (
						<TurnstileWidget
							onSuccess={(args) => dataSubmission(args)}
							forceCaptcha={true}
						/>
					 )}
					
					<p className={"open-400w-12s-18h opacity-60"}>
						You may receive SMS updates from NueGo and can opt out at any time.
					</p>

					<p
						className={
							"open-600w-12s-18h teal-22BBB0-color mb-0 text-center cursor-pointer"
						}
						onClick={() => store.dispatch(setTermsConditionsModalAction(true))}
					>
						Terms & Conditions
					</p>
				</div>
			)}
		</>
	);
};

const mapDispatchToStore = (dispatch) => ({
	setTypeToStore: (data) => dispatch(setAuthType(data)),
	setSignUpDataToStore: (data) => dispatch(setSignupDataSubmission(data)),
	setLoginDataToStore: (data) => dispatch(setLoginDataSubmission(data)),
});

export default connect(null, mapDispatchToStore)(SignInDesktop);
