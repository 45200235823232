import React from 'react'
import './CityDetails.scss'
import SeoFooter from '../../../Home/component/SeoFooter/SeoFooter'
import headerIcon from '../../../../assets/re-rewamp/Lightning (1).png'
const coachrouteimg = process.env.REACT_APP_CDN_LINK + `assets/images/CoachDetailsNew/Trendingplaces2.svg`

const fromCity = `## About Chennai
&nbsp;  
Bus ticket booking with NueGo is extremely convenient, second to only traveling on a NueGo bus. NueGo is India’s first intercity electric bus service with a presence in 100+ cities PAN India. Electric buses make traveling more efficient, quieter, and serene. Bus tickets on NueGo are also inexpensive and the price will surprise you. Bus ticket booking is very easy and affordable through NueGo App & web; and traveling with NueGo comes with a bunch of perks including Luxurious Premium Lounges, Clean Washrooms at Mid-Points and Live GPS Tracking, a facility on our user-friendly app which is an efficient booking platform. We ensure that your bus ticket booking online is a hassle-free process through this website and we have several offers & discounts around the year. Your sleeper bus booking is going to be as smooth as your sleeper bus rides with NueGo.  
&nbsp;  
NueGo currently has about 450+ daily bus departures to different cities around the country for anyone who wants a hassle-free bus ticket booking experience and a peaceful bus journey. We have bus routes from Delhi to a plethora of Indian cities as well as bus routes from Chennai, Hyderabad, Bangalore, and Jaipur. We also offer bus routes to several tourist places like Agra, Shimla, Dehradun, and places of religious importance like Haridwar, Rishikesh, Tirupati, and Ujjain.
&nbsp;  
&nbsp;  
## Online AC Sleeper Bus Ticket Booking at Lowest Price  
&nbsp;  
You can choose to book an AC sleeper bus for overnight journeys by choosing our sleeper bus booking services. Our AC sleeper bus is designed with the comfort and convenience of the customer in mind and delivers with its noiseless engine and comfortable trips. Furthermore, our AC sleeper buses come with a lot of features like premium seats, complimentary water bottles, CCTV, reading lights, live geo-tracking or bus live location facility of the bus, a USB port for charging your electronic devices, and an e-ticketing system. Some of the features of a NueGo electric bus include:
 
- Speed limit cap of 80 km per hour
- 6 CCTVs within the bus 
- Panic buttons for passengers
- Hammer at each window with an emergency exit
- Two exit hatches on top
- Reverse Parking Assist system
- No Ignition while charging
- Battery temperature and tire pressure monitoring systems
- Low-Speed Alarm to detect pedestrians
- Fog Lights below the headlights of the bus
- Good ground clearance, ride height with kneeling features
- Battery low charging, reverse camera, and motor temperature alarm
- Air quality control and cabin temperature monitoring system
- Collision and lane change alerting system
- Live monitoring of driver fatigue and possible mobile use
- Alcohol Detection Sensors
- Breathalyzer test for all drivers to detect possible alcohol intake before the journey  
 
&nbsp;  
## Benefits of Booking Bus Tickets Online Via NueGo  
&nbsp;  
There are several benefits and advantages that come with booking bus tickets via NueGo. Firstly, the process of booking buses is very easy and straightforward. Secondly, there is a laundry list of benefits like cancellation of bus tickets in case you changed your plans, with a refund. Other benefits include the option to change a trip detail, like rescheduling bus tickets in case you change the date or bus timings of your travel and modifying the ticket for instances where you may decide a different pick up / drop point. Furthermore, you only have to carry one photo ID with your bus tickets which makes the process very easy.
 
The NueGo app provides you with a lot of important details about your bus ride as well. You can check what facilities are available in the buses you select by looking at the icons that are presented beside the “select seats” option before you select your seats. Clicking on the bus details option gives you a stop-by-stop detailed list of your bus route. Our bus rides are very safe in general and we provide [NueGo lounge](/travel-lounge) services to ensure our customers’ convenience so that they can be ready for their journey. Our bus ticket booking services have a lot of flexible options which make them a very comfortable choice for most of our customers.
 
One of the greatest advantages of bus ticket booking online on NueGo Website or Mobile Application is that Bus booking online reduces the necessity of standing in queues at ticket counters to book your bus tickets. Furthermore, useful options like rescheduling or modifying your journey allows you to make last minute changes to your trip from the comfort of your homes. The bus fare is also reduced with our offers. To create a sustainable future, NueGo ensures minimal use of paper in the bus ticket booking process as all customers of NueGo can do their bus booking online and sleeper bus booking on our state-of-the-art NueGo app.
&nbsp;  
&nbsp;  
## How to Book Bus Tickets Online on NueGo 
&nbsp;  
Bus ticket booking online is an extremely hassle-free process on NueGo.
- On the NueGo app or website, you select your departure city, your destination city, and your travel bus date.
- You will then be displayed multiple buses departing at different intervals for that day. You can choose the time that is convenient for your travel and go ahead with your bus ticket booking on our user friendly app. 
- You can then move on to selecting your preferred seats which guarantee a confirmed booking. For women, NueGo hosts a special _Pink Seat_ option, where once a woman books a seat, the seat adjacent to her’s automatically becomes a “pink seat” and is reserved for another woman only. If no other woman books that seat, it is left empty for the trip. 
- After seat selection, you then proceed to select your preferred pick-up point & drop-off location.
- You then enter the passenger details and opt to avail coupons with great deals and bus booking offers that reduce the pricing of your bus ticket.
- The final stage of the bus ticket booking process is payment which can be made using NueGo wallet to make hassle-free payments or via UPI / NetBanking / Debit & Credit cards; and VOILA! Your booking is now complete!
 
&nbsp;  
## Bus Ticket Booking Online Offers
&nbsp;    
We at NueGo pay ample attention to customer satisfaction and comfort. Therefore, we have a wide range of [bus booking offers](/bus-booking-offers) available for you whenever you do bus booking online to reduce the price of your ticket. You can avail these offers by entering the promo codes in the slot available for it when you book the tickets and see the bus fare go way down. Some popular promo codes include earning Green Miles Cashback on all your journeys, which are valid for 3 months after the date of your travel. Another popular offer is “RETURN10” which gives you upto 10% off on all your return trips; or you can even avail the referral discount wherein you refer NueGo to a friend and you both earn 50 Green Miles.  
Please note that our offers keep changing throughout the year & these online offers are usually available for all our electric bus booking and ac bus ticket booking options. Furthermore, we also conduct lucky draws for specific intercity bus booking online options where winners receive free gifts.
&nbsp;  
&nbsp;  
## Zero Tail-Pipe Emissions
&nbsp;  
With the demanding complications of environmental degradation, it is our responsibility to do our best in reducing carbon emissions  and the carbon footprint we leave behind as we travel to different places. Here at NueGo, we make it our sole purpose to reduce the carbon emissions by ensuring Zero tailpipe emission Bus rides. By opting for electric buses as a mode of travel, we significantly reduce the effect our buses have on the environment. Furthermore, we also ensure safe and clean transportation with lounges near the bus stop to provide facilities like washrooms and waste disposal which also reduce the risk of littering.
 
NueGo is one of the few Indian companies trying to build a platform to provide Electric Mobility-as-a-Service (eMaaS). Our green initiative helps provide a sustainable and efficient means of travel with minimal damage to the environment. Facilities like bus booking online ensures reduction of paper use and electronic payment for bus tickets further reduces the scope for paper use in the entire process of your bus ticket booking.
 
Choose NueGo for the experience of seamlessly transitioning through your entire vacation with minimal hassle and worries.`


const toCity = `## About Bangalore
&nbsp;  
Bus ticket booking with NueGo is extremely convenient, second to only traveling on a NueGo bus. NueGo is India’s first intercity electric bus service with a presence in 100+ cities PAN India. Electric buses make traveling more efficient, quieter, and serene. Bus tickets on NueGo are also inexpensive and the price will surprise you. Bus ticket booking is very easy and affordable through NueGo App & web; and traveling with NueGo comes with a bunch of perks including Luxurious Premium Lounges, Clean Washrooms at Mid-Points and Live GPS Tracking, a facility on our user-friendly app which is an efficient booking platform. We ensure that your bus ticket booking online is a hassle-free process through this website and we have several offers & discounts around the year. Your sleeper bus booking is going to be as smooth as your sleeper bus rides with NueGo.  
&nbsp;  
NueGo currently has about 450+ daily bus departures to different cities around the country for anyone who wants a hassle-free bus ticket booking experience and a peaceful bus journey. We have bus routes from Delhi to a plethora of Indian cities as well as bus routes from Chennai, Hyderabad, Bangalore, and Jaipur. We also offer bus routes to several tourist places like Agra, Shimla, Dehradun, and places of religious importance like Haridwar, Rishikesh, Tirupati, and Ujjain.
&nbsp;  
&nbsp;  
## Online AC Sleeper Bus Ticket Booking at Lowest Price  
&nbsp;  
You can choose to book an AC sleeper bus for overnight journeys by choosing our sleeper bus booking services. Our AC sleeper bus is designed with the comfort and convenience of the customer in mind and delivers with its noiseless engine and comfortable trips. Furthermore, our AC sleeper buses come with a lot of features like premium seats, complimentary water bottles, CCTV, reading lights, live geo-tracking or bus live location facility of the bus, a USB port for charging your electronic devices, and an e-ticketing system. Some of the features of a NueGo electric bus include:
 
- Speed limit cap of 80 km per hour
- 6 CCTVs within the bus 
- Panic buttons for passengers
- Hammer at each window with an emergency exit
- Two exit hatches on top
- Reverse Parking Assist system
- No Ignition while charging
- Battery temperature and tire pressure monitoring systems
- Low-Speed Alarm to detect pedestrians
- Fog Lights below the headlights of the bus
- Good ground clearance, ride height with kneeling features
- Battery low charging, reverse camera, and motor temperature alarm
- Air quality control and cabin temperature monitoring system
- Collision and lane change alerting system
- Live monitoring of driver fatigue and possible mobile use
- Alcohol Detection Sensors
- Breathalyzer test for all drivers to detect possible alcohol intake before the journey  
 
&nbsp;  
## Benefits of Booking Bus Tickets Online Via NueGo  
&nbsp;  
There are several benefits and advantages that come with booking bus tickets via NueGo. Firstly, the process of booking buses is very easy and straightforward. Secondly, there is a laundry list of benefits like cancellation of bus tickets in case you changed your plans, with a refund. Other benefits include the option to change a trip detail, like rescheduling bus tickets in case you change the date or bus timings of your travel and modifying the ticket for instances where you may decide a different pick up / drop point. Furthermore, you only have to carry one photo ID with your bus tickets which makes the process very easy.
 
The NueGo app provides you with a lot of important details about your bus ride as well. You can check what facilities are available in the buses you select by looking at the icons that are presented beside the “select seats” option before you select your seats. Clicking on the bus details option gives you a stop-by-stop detailed list of your bus route. Our bus rides are very safe in general and we provide [NueGo lounge](/travel-lounge) services to ensure our customers’ convenience so that they can be ready for their journey. Our bus ticket booking services have a lot of flexible options which make them a very comfortable choice for most of our customers.
 
One of the greatest advantages of bus ticket booking online on NueGo Website or Mobile Application is that Bus booking online reduces the necessity of standing in queues at ticket counters to book your bus tickets. Furthermore, useful options like rescheduling or modifying your journey allows you to make last minute changes to your trip from the comfort of your homes. The bus fare is also reduced with our offers. To create a sustainable future, NueGo ensures minimal use of paper in the bus ticket booking process as all customers of NueGo can do their bus booking online and sleeper bus booking on our state-of-the-art NueGo app.
&nbsp;  
&nbsp;  
## How to Book Bus Tickets Online on NueGo 
&nbsp;  
Bus ticket booking online is an extremely hassle-free process on NueGo.
- On the NueGo app or website, you select your departure city, your destination city, and your travel bus date.
- You will then be displayed multiple buses departing at different intervals for that day. You can choose the time that is convenient for your travel and go ahead with your bus ticket booking on our user friendly app. 
- You can then move on to selecting your preferred seats which guarantee a confirmed booking. For women, NueGo hosts a special _Pink Seat_ option, where once a woman books a seat, the seat adjacent to her’s automatically becomes a “pink seat” and is reserved for another woman only. If no other woman books that seat, it is left empty for the trip. 
- After seat selection, you then proceed to select your preferred pick-up point & drop-off location.
- You then enter the passenger details and opt to avail coupons with great deals and bus booking offers that reduce the pricing of your bus ticket.
- The final stage of the bus ticket booking process is payment which can be made using NueGo wallet to make hassle-free payments or via UPI / NetBanking / Debit & Credit cards; and VOILA! Your booking is now complete!
 
&nbsp;  
## Bus Ticket Booking Online Offers
&nbsp;    
We at NueGo pay ample attention to customer satisfaction and comfort. Therefore, we have a wide range of [bus booking offers](/bus-booking-offers) available for you whenever you do bus booking online to reduce the price of your ticket. You can avail these offers by entering the promo codes in the slot available for it when you book the tickets and see the bus fare go way down. Some popular promo codes include earning Green Miles Cashback on all your journeys, which are valid for 3 months after the date of your travel. Another popular offer is “RETURN10” which gives you upto 10% off on all your return trips; or you can even avail the referral discount wherein you refer NueGo to a friend and you both earn 50 Green Miles.  
Please note that our offers keep changing throughout the year & these online offers are usually available for all our electric bus booking and ac bus ticket booking options. Furthermore, we also conduct lucky draws for specific intercity bus booking online options where winners receive free gifts.
&nbsp;  
&nbsp;  
## Zero Tail-Pipe Emissions
&nbsp;  
With the demanding complications of environmental degradation, it is our responsibility to do our best in reducing carbon emissions  and the carbon footprint we leave behind as we travel to different places. Here at NueGo, we make it our sole purpose to reduce the carbon emissions by ensuring Zero tailpipe emission Bus rides. By opting for electric buses as a mode of travel, we significantly reduce the effect our buses have on the environment. Furthermore, we also ensure safe and clean transportation with lounges near the bus stop to provide facilities like washrooms and waste disposal which also reduce the risk of littering.
 
NueGo is one of the few Indian companies trying to build a platform to provide Electric Mobility-as-a-Service (eMaaS). Our green initiative helps provide a sustainable and efficient means of travel with minimal damage to the environment. Facilities like bus booking online ensures reduction of paper use and electronic payment for bus tickets further reduces the scope for paper use in the entire process of your bus ticket booking.
 
Choose NueGo for the experience of seamlessly transitioning through your entire vacation with minimal hassle and worries.`


export default function CityDetails({routeDetails}) {
  
  return (
    <div className="city-details">
        <div className="container">
        {<div className="head-img">
                {/* <img className='icon-img' src={headerIcon} alt="NueGo popular routes" /> */}
                {/* <h2 className='title'>Know the Cities</h2> */}
            </div>}
            <SeoFooter heading={`About ${routeDetails?.route?.source.cityName}`} content={routeDetails?.route?.source?.about}  />
            <SeoFooter heading={`About ${routeDetails?.route?.destination.cityName}`} content={routeDetails?.route?.destination?.about}   />
            <img src={coachrouteimg} alt="" className="w-100" />
        </div>
    </div>
  )
}
