import React from 'react'
import './RouteDetails.scss'
import flip from "../../../../assets/revamp/flip2.png";
import coucthimg from '../../../../assets/re-rewamp/OurRoutes - Solid.png'

const map_pin = process.env.REACT_APP_CDN_LINK + `assets/images/map-pin.png`

export default function RouteDetails({routeDetails,onFlip, isDesktopOrLaptop}) {
    
  return (
    <div className="route-details">
    <div className="container">
        <div className={`d-flex ${isDesktopOrLaptop ? 'flex-row': 'flex-column'} flex-row justify-content-between align-items-center mb-3`}>
            <h2><img src={coucthimg} alt="" />Distance between {routeDetails?.route?.source.cityName} to {routeDetails?.route?.destination.cityName}</h2>
            <div className='search-inputs-container-sub-div-distance'>
                <div 
                className='source-input-bar'
                id={"search-source"}

                >
                    <img
                    src={map_pin}
                    className={"location"}
                    alt={"source"}
                />

                    <input
                    type={"text"}
                    pattern="[A-Za-z]"
                    value={routeDetails?.route?.source.cityName}
                    className={"start-location"}
                />
                </div>
                <img
                src={flip}
                onClick={() => onFlip(routeDetails?.route?.destination.cityName, routeDetails?.route?.source.cityName)}
                className={"flip"}
                alt={"flip"}
                />
                <div 
                className='destination-input-bar'
                id={"search-destination"}
                >
                    <img
                    src={map_pin}
                    className={"location"}
                    alt={"destination"}
                />
                    <input
                        type={"text"}
                        className={"end-location"}
                        value={routeDetails?.route?.destination.cityName}
                    />

                </div>
            
            </div>
        </div>
        <ul>
            <li>
                <p>From City</p>
                <h3>{routeDetails?.route?.source.cityName}</h3>
            </li>
            <li>
                <p>To City</p>
                <h3>{routeDetails?.route?.destination.cityName}</h3>
            </li>
            <li>
                <p>Distance between Chennai to Bangalore by Road</p>
                <h3>{routeDetails?.distanceInKm}</h3>
            </li>
            <li>
                <p>Travel Time between Chennai to Bangalore by Road</p>
                <h3>{routeDetails?.travelTime}</h3>
            </li>

        </ul>
    </div>
</div>
  )
}
